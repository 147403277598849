import * as React from 'react';
import { Route } from 'react-router-dom';
import * as async from 'lib/async';

const Install = async.Page('Install'); // import Forgot from 'views/Forgot';
const Forgot = async.Page('Forgot'); // import Forgot from 'views/Forgot';
const Reset = async.Page('Reset'); // import Reset from 'views/Reset';
const Register = async.Page('Register'); // import Register from 'views/Register';
const Book = async.Page('Book'); // import Register from 'views/Register';
const Contact = async.Page('Contact'); // import Register from 'views/Register';
const Help = async.Page('Help'); // import Register from 'views/Register';

export default [
  <Route exact path="/forgot" component={Forgot} noLayout key="forgot" />,
  <Route exact path="/reset" component={Reset} noLayout key="reset" />,
  <Route exact path="/register" component={Register} noLayout key="register" />,
  <Route exact path="/install" component={Install} key="install" />,

  // dirty hack is dirty, for backwards compatibility
  <Route exact path="/client/forgot" component={Reset} noLayout key="client" />,

  // business routes
  <Route exact path="/book" component={Book} key="book" />,
  <Route exact path="/contact" component={Contact} key="contact" />,
  <Route exact path="/help" component={Help} key="help" />,
];
