import React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery, Typography } from '@material-ui/core';
import {
  useTranslate,
  MenuItemLink,
  DashboardMenuItem,
  getResources,
} from 'react-admin';
import MenuItem from '@material-ui/core/MenuItem';
import DefaultIcon from '@material-ui/icons/ViewList';
import HelpIcon from '@material-ui/icons/HelpOutline';
import EmailIcon from '@material-ui/icons/Email';
//import DashboardIcon from '@material-ui/icons/HomeOutlined';
//import PieChartIcon from '@material-ui/icons/PieChart';
import BookIcon from '@material-ui/icons/EventAvailable';
import { makeStyles } from '@material-ui/core/styles';
import logo from 'theme/MyLogo';
import { secondaryColour, lightTextColour } from 'theme/MyTheme';
import { buildVersion } from 'lib/buildDate';

export const useStyles = makeStyles({
  logoWrap: {
    display: 'flex',
    alignItems: 'center',
    background: secondaryColour,
    color: lightTextColour,
    height: 55,
  },
  logo: {
    maxHeight: '40px',
    background: secondaryColour,
    marginLeft: 15,
  },
  menuTitle: {
    paddingLeft: 10,
  },
});

export const MyMenu = ({ onMenuClick, logout }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  // console.log("{MyMenu}", {sidebarOpen:open,state});
  return (
    <div>
      {isXSmall && (
        <div className={classes.logoWrap}>
          <img className={classes.logo} src={logo} />
          <Typography className={classes.menuTitle}>
            {translate('ra.menu.title', { _: 'Menu' })}
          </Typography>
        </div>
      )}
      <DashboardMenuItem />
      {/* 
      <MenuItemLink
        to="/"
        primaryText={translate('ra.menu.dashboard', { _: 'Dashboard' })}
        leftIcon={<DashboardIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
       */}
      {resources.map((resource) =>
        resource.options && resource.options.label ? (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={
              (resource.options && resource.options.label) || resource.name
            }
            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        ) : null
      )}
      <MenuItemLink
        to="/book"
        primaryText={translate('ra.menu.book', { _: 'Book' })}
        leftIcon={<BookIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to="/contact"
        primaryText={translate('ra.menu.contact', { _: 'Contact' })}
        leftIcon={<EmailIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to="/help"
        primaryText={translate('ra.menu.help', { _: 'Help' })}
        leftIcon={<HelpIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {isXSmall && logout}
      <MenuItem>
        <Typography style={{ fontSize: 10 }}>v{buildVersion}</Typography>
      </MenuItem>
    </div>
  );
};
