import React, { forwardRef } from 'react';
import CryptoJS from 'crypto-js';
import { connect, useSelector } from 'react-redux';
import {
  useTranslate,
  useQueryWithStore, // useDataProvider,
  UserMenu,
  MenuItemLink,
} from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import EmailIcon from '@material-ui/icons/Email';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Token from 'lib/token';
import { InstallPromptMenuItem } from 'components/InstallPrompt';

const useStyles = makeStyles({
  avatar: {
    height: 30,
    width: 30,
  },
});

const MyCustomIcon = connect()(() => {
  const classes = useStyles();

  // get user from state, this should be enough
  const userFromState = useSelector((state) => state.user);
  // but can't figure out how to dispatch yet, so, do it the long way
  const { u: userId } = Token.get() || userFromState;
  // console.log('MyCustomIcon', {userFromState, userId, props});
  const { loading, error, data } = useQueryWithStore({
    type: 'getOne',
    resource: 'profile',
    payload: { id: userId },
  });

  if (loading) return ''; // <Loading />;
  if (error) return ''; // <Error />;
  if (!data) return null;

  const md5 = CryptoJS.MD5(data.email).toString();
  const img = `https://www.gravatar.com/avatar/${md5}`;

  // console.log('MyCustomIcon state', { data, md5, img, userFromState, loading, error });

  return <Avatar className={classes.avatar} src={img} />;
});

export const ShortcutsMenu = forwardRef(({ onClick }, ref) => {
  const translate = useTranslate();
  return (
    <MenuItemLink
      ref={ref}
      to="/contact"
      primaryText={translate('ra.menu.settings', { _: 'Contact' })}
      leftIcon={<EmailIcon />}
      onClick={onClick} // Close the menu on click
    />
  );
});

// eslint-disable-next-line react/display-name
export const SettingsMenu = forwardRef(({ onClick }, ref) => {
  const translate = useTranslate();
  return (
    <MenuItemLink
      ref={ref}
      to="/settings"
      primaryText={translate('ra.menu.settings', { _: 'Configuration' })}
      leftIcon={<SettingsIcon />}
      onClick={onClick} // close the menu on click
    />
  );
});

export const MyUserMenu = (props) => (
  <UserMenu icon={<MyCustomIcon />} {...props}>
    <InstallPromptMenuItem />
    <ShortcutsMenu />
    {/* <SettingsMenu /> */}
  </UserMenu>
);
