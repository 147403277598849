import React, { useEffect } from 'react';
import Config from './lib/config';

import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import {
  Admin,
  Resource,
  //ListGuesser,
  //ShowGuesser,
  resolveBrowserLocale,
} from 'react-admin';

import './lib/security';
import authProvider from './lib/authProvider';
import dataProvider from './lib/dataProvider';
import i18nProvider from './lib/i18nProvider';
import * as async from './lib/async';

//import InvoiceIcon from '@material-ui/icons/Receipt';
//import JobIcon from '@material-ui/icons/LocalShipping';
import JobIcon from '@material-ui/icons/LocalShipping';
import CalendarIcon from '@material-ui/icons/EventOutlined';
import ItemIcon from '@material-ui/icons/ToysOutlined';
import InvoiceIcon from '@material-ui/icons/DescriptionOutlined';

//import jsonServerProvider from 'ra-data-json-server';
//const dataProvider = jsonServerProvider('https://jsonplaceholder.typicode.com');

import 'lib/install';

import { MyTheme } from './theme/MyTheme';
import { MyLayout } from './theme/MyLayout';
import { MyLogoutButton } from './theme/MyLogout';

import history from './lib/history';
// NB: doesn't work in multi-tenant cross-domain apps

import customRoutes from './routes';
import customReducers from './reducers';

console.log('Config', { Config });

const Login = async.Page('Login');
const Dashboard = async.Page('Dashboard');

const Invoice = async.Resource('Invoice');
const InvoiceJob = async.Resource('InvoiceJob');
const InvoiceItem = async.Resource('InvoiceItem');
import 'react-block-ui/style.css';
import 'App.scss';

export const App = () => {
  console.log('App()');

  //http://localhost:3000/client/forgot?crypto=26577.c8a9d36bd35903732a6a407ae05cce17e0e7246a820ef33d1cfcda618fa17b6e@20200802132821.90371870592604c9cbbaf49692810aaa0176d12dd467d0fa402a357be1580dbd&r=/checkout#/login
  //if (typeof window !== 'undefined') {
  //  const { hash, pathname, search } = window.location;
  //  const forgot = '/client/forgot';
  //  useEffect(() => {
  //    console.log('start', { hash, forgot, pathname, search });
  //    if (pathname === forgot) {
  //      console.log('start push', { hash, forgot, pathname, search });
  //      console.log('start redirect to', { location: forgot + search });
  //      //window.location.hash = forgot+search
  //      window.location = '/#' + forgot + search;
  //    }
  //  }, [hash, search, pathname]);
  //  console.log('start go', { hash, forgot, pathname, search });
  //  if (pathname === forgot && hash !== `#${pathname}${search}`) return '';
  //}

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ThemeProvider theme={MyTheme}>
        <CssBaseline />
        <style>{`
            @keyframes spin {
                 0% { transform: rotate(360deg); }
                 100% { transform: rotate(0deg); }
            }
        `}</style>
        <Admin
          history={history}
          // NB: doesn't work in multi-tenant cross-domain apps

          theme={MyTheme}
          layout={MyLayout}
          logout={MyLogoutButton}
          loginPage={Login}
          dashboard={Dashboard}
          initialState={{}}
          authProvider={authProvider(Config)}
          dataProvider={dataProvider(Config)}
          i18nProvider={i18nProvider}
          locale={
            typeof window !== 'undefined' ? resolveBrowserLocale() : undefined
          }
          customRoutes={customRoutes}
          customReducers={customReducers}
        >
          <Resource name="fw.dashboard.stats" />

          <Resource
            name="fw.invoice.job"
            options={{ label: 'Jobs' }}
            icon={JobIcon}
            show={InvoiceJob.Show}
            list={InvoiceJob.List}
          />
          <Resource
            name="fw.invoice"
            options={{ label: 'Invoices' }}
            icon={InvoiceIcon}
            show={Invoice.Show}
            list={Invoice.List}
          />
          <Resource name="fw.invoice.component" />
          <Resource name="fw.invoice.signature" />
          <Resource name="fw.invoice.image" />
          <Resource name="fw.invoice.audio" />
          <Resource name="fw.invoice.video" />
          <Resource name="fw.invoice.track" />
          <Resource name="fw.invoice.record" />
          <Resource name="fw.invoice.material" />
          <Resource name="fw.invoice.coupon" />
          <Resource name="fw.invoice.discount" />
          <Resource name="fw.invoice.refund" />
          <Resource name="fw.invoice.transaction" />
          <Resource name="fw.invoice.billination" />
          <Resource name="fw.invoice.destination" />
          <Resource name="fw.invoice.item" show={InvoiceItem.Show} />

          <Resource name="fw.invoice.folder" />
          <Resource name="fw.invoice.sector" />
          <Resource name="fw.invoice.department" />

          {/* <Resource name="fw.quote" options={{label: "Quotes"}} icon={QuoteIcon} show={Quote.Show} list={Quote.List} />  */}
          <Resource name="fw.quote" />
          <Resource name="fw.quote.component" />
          <Resource name="fw.quote.signature" />
          <Resource name="fw.quote.image" />
          <Resource name="fw.quote.audio" />
          <Resource name="fw.quote.video" />
          <Resource name="fw.quote.job" />
          <Resource name="fw.quote.item" />
          <Resource name="fw.quote.coupon" />
          <Resource name="fw.quote.discount" />
          <Resource name="fw.quote.billination" />
          <Resource name="fw.quote.destination" />

          <Resource name="fw.page" />
          <Resource name="fw.page.album" />
          <Resource name="fw.page.attachment" />
          <Resource name="fw.page.audio" />
          <Resource name="fw.page.brand" />
          <Resource name="fw.page.brochure" />
          <Resource name="fw.page.collection" />
          <Resource name="fw.page.colour" />
          <Resource name="fw.page.comment" />
          <Resource name="fw.page.download" />
          <Resource name="fw.page.faq" />
          <Resource name="fw.page.file" />
          <Resource name="fw.page.image" />
          <Resource name="fw.page.model" />
          <Resource name="fw.page.price" />
          <Resource name="fw.page.review" />
          <Resource name="fw.page.scrap" />
          <Resource name="fw.page.size" />
          <Resource name="fw.page.video" />

          <Resource name="fw.product" />
          <Resource name="fw.product.album" />
          <Resource name="fw.product.attachment" />
          <Resource name="fw.product.audio" />
          <Resource name="fw.product.brand" />
          <Resource name="fw.product.brochure" />
          <Resource name="fw.product.collection" />
          <Resource name="fw.product.colour" />
          <Resource name="fw.product.comment" />
          <Resource name="fw.product.download" />
          <Resource name="fw.product.faq" />
          <Resource name="fw.product.file" />
          <Resource name="fw.product.image" />
          <Resource name="fw.product.model" />
          <Resource name="fw.product.price" />
          <Resource name="fw.product.review" />
          <Resource name="fw.product.scrap" />
          <Resource name="fw.product.size" />
          <Resource name="fw.product.video" />

          <Resource name="fw.service" />
          <Resource name="fw.service.option" />

          <Resource name="fw.account" />
          <Resource name="fw.account.contact" />
        </Admin>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default App;
