import { defaultTheme } from 'react-admin';
import { createTheme } from '@material-ui/core/styles';
// import grey from '@material-ui/core/colors/grey';
// import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

export const primaryColour = process.env.REACT_APP_primaryColour;
export const secondaryColour = process.env.REACT_APP_secondaryColour;
export const lightTextColour = process.env.REACT_APP_lightTextColour;

console.log('createTheme', { primaryColour, secondaryColour, lightTextColour });

// create blank theme to expose breakpoints,
const theme = createTheme({
  ...defaultTheme,

  sidebar: {
    width: 200, // The default value is 240
    closedWidth: 55, // The default value is 55
    overflowX: 'hidden',
    //paddingTop: 120,
    background: '#f00',
  },

  palette: {
    primary: {
      // main: green[900],
      main: primaryColour, // [500],
    },
    secondary: {
      // main: green[900],
      main: secondaryColour, // [500],
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
});

// then overwrite theme and we can use breakpoints
// https://stackoverflow.com/a/56320113
export const MyTheme = {
  ...theme,
  overrides: {
    MuiButton: {
      // override the styles of all instances of this component
      root: {
        // Name of the rule
        // color: 'white', // Some CSS
      },
    },
    MuiFormControl: {
      root: {
        width: '100%', // background: 'red',
      },
    },
    MuiCard: {
      root: {
        width: '100%',
        marginBottom: '20px',
      },
    },
    MuiCardContent: {
      root: {
        width: '100%', // background: 'red',
      },
    },
    MuiCardActionArea: {
      root: {
        width: '100%', // background: 'red',
      },
    },
    MuiListItemIcon: {
      root: {
        marginLeft: '10px',
      },
    },
    //RaLabeled: {
    //  root: {
    //    width: '100%', // background: 'red',
    //  },
    //},
    RaSingleFieldList: {
      root: {
        width: '100%', // background: 'red',
      },
      link: {
        display: 'block',
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      },
    },
  },
};

export default MyTheme;
