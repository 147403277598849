import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// import { ThemeProvider } from '@material-ui/styles';

import { MyMenu } from './MyMenu';
import { MyAppBar } from './MyAppBar';
import { MySidebar } from './MySidebar';
import { MyNotification } from './MyNotification';

/*
import {
  AppLocationContext,
  Breadcrumb,
  ResourceBreadcrumbItems,
} from '@react-admin/ra-navigation';
*/

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
    minHeight: '100vh',
    backgroundColor: theme.palette.background.default,
    position: 'relative',
  },
  appFrame: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'auto',
  },
  contentWithSidebar: {
    display: 'flex',
    flexGrow: 1,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 2,
    padding: theme.spacing(3),
    marginTop: 30, //'4em',
    paddingLeft: 15,
    paddingRight: 20,
    background: '#fff',
    zIndex: 1,
  },
}));

// Include this component in your views. See below
/*
export const MyBreadcrumb = (props) => (
  <Breadcrumb {...props}>
    <ResourceBreadcrumbItems />
  </Breadcrumb>
);
*/

export const MyLayout = ({ children, dashboard, logout, title }) => {
  const classes = useStyles();
  //const dispatch = useDispatch();

  const open = useSelector((state) => state.admin.ui.sidebarOpen);

  // useEffect(() => {
  // dispatch(setSidebarVisibility(true));
  // }, [setSidebarVisibility]);

  return (
    // <AppLocationContext hasDashboard={!!props.dashboard}>
    <div className={classes.root}>
      <div className={classes.appFrame}>
        <MyAppBar title={title} open={open} logout={logout} />
        <main className={classes.contentWithSidebar}>
          <MySidebar>
            <MyMenu logout={logout} hasDashboard={!!dashboard} />
          </MySidebar>
          <div className={classes.content}>{children}</div>
        </main>
        <MyNotification />
      </div>
    </div>
    // </AppLocationContext>
  );
};
/*
<AppLocationContext hasDashboard={!!props.dashboard}>
  <Layout
    {...props}
    appBar={() => <MyAppBar title={title} open={open} logout={logout} />}
    sidebar={() => (
      <MySidebar>
        <MyMenu logout={logout} hasDashboard={!!dashboard} />
      </MySidebar>
    )}
    menu={() => <MyMenu logout={logout} hasDashboard={!!dashboard} />}
    notification={MyNotification}
  >
    <div className={classes.content}>{children}</div>
  </Layout>
</AppLocationContext>
*/

MyLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  dashboard: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  // logout: ComponentPropType,
  title: PropTypes.string.isRequired,
};

export default MyLayout;
